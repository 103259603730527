/*eslint-disable*/
import M$plugin$QueryAttributes from '/home/carmenmarquez/Escritorio/apiol/API-CNIG/api-ign-js/src/plugins/queryattributes/src/facade/js/queryattributes';
import M$control$QueryAttributesControl from '/home/carmenmarquez/Escritorio/apiol/API-CNIG/api-ign-js/src/plugins/queryattributes/src/facade/js/queryattributescontrol';
import M$impl$control$QueryAttributesControl from '/home/carmenmarquez/Escritorio/apiol/API-CNIG/api-ign-js/src/plugins/queryattributes/src/impl/ol/js/queryattributescontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.QueryAttributes = M$plugin$QueryAttributes;
window.M.control.QueryAttributesControl = M$control$QueryAttributesControl;
window.M.impl.control.QueryAttributesControl = M$impl$control$QueryAttributesControl;
